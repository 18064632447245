export const ARTICLE_LIST = [
  {
    title: '高瓴杀入餐饮，联手源码投资潮汕美食“仙味爷爷”',
    time: '2021.07.13',
    summary: '餐饮赛道，“热到发指”。继兰州拉面成为资本的“新宠”之后，粉面、火锅、炸串乃至烘焙战队也频传捷报，金额不断攀升',
    link: 'https://36kr.com/p/1308633942985352',
    poster: require('@/assets/news/news1.jpg'),
  },
  {
    title: '源码、高瓴重注这家创企，6000万元为调味料行业的新消费故事买单',
    time: '2021.07.15',
    summary: '7月15日，新锐潮汕风味食品公司仙味爷爷宣布完成6000万Pre-A轮融资，由源码资本领投，高瓴创投跟投',
    link: 'https://www.cyzone.cn/article/641160.html',
    poster: require('@/assets/news/news2.jpg'),
  },
  {
    title: '仙味爷爷完成6000万Pre-A轮融资 成立10月估值近3亿',
    time: '2021.07.16',
    summary: '疫情让一众地方美食出圈，如今，潮汕的方便美食也要来了',
    link: 'https://www.chinaventure.com.cn/news/80-20210714-363212.html',
    poster: require('@/assets/news/news3.jpg'),
  },
  {
    title: '潮州市厨师协会与副会长单位「仙味爷爷」达成战略合作，共同打造美食名片',
    time: '2021.07.22',
    summary: '潮州市厨师协会与「仙味爷爷」签署了战略合作框架协议，将共同深入发掘潮州美食潜力',
    link: 'https://www.163.com/news/article/GEULKML200019OH3.html',
    poster: require('@/assets/news/news4.jpg'),
  },
];

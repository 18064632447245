<template>
  <div class="home">
    <div class="section-vision"></div>
    <div class="section-profile row-center">
      <div class="section-box profile">
        <div class="profile-body">
          <div class="icon"></div>
          <h1 class="title">公司简介</h1>
          <p class="content">
            仙味爷爷，一个新锐的潮汕食品品牌。<br />
            创始人陈湘生长于潮汕，行走于世界之间，是位对味觉极其敏锐和挑剔的青年企业家，在吃遍各地美味的同时，深悟到潮汕味道“鲜”的美妙所在。但潮汕美食因老一辈小作坊运作思维和但潮汕美食因老一辈小作坊运作思维和但潮汕美食因老一辈小作坊运作思维和但潮汕美食因老一辈小作坊运作思维和但潮汕美食因老一辈小作坊运作思维和但潮汕美食因老一辈小作坊运作思维和
          </p>
          <router-link class="more" to="about">了解更多</router-link>
        </div>
        <div class="profile-foot">
          <img src="@/assets/home/profile1.jpg" alt="" />
        </div>
      </div>
    </div>
    <div class="section-investment row-center" v-if="false">
      <div class="section-box investment">
        <div class="investment-head">
          <h1 class="title">投资机构</h1>
        </div>
        <div class="investment-body">
          <div class="item">
            <img src="@/assets/home/investment1.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/investment2.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/investment3.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section-team row-center">
      <div class="section-box team">
        <div class="team-head">
          <h1 class="title">核心团队</h1>
        </div>
        <div class="team-body">
          <p>
            我们的团队成员具有多元化的行业背景以及全球化的投资视角和资源，教育及工作背景覆盖技术、市场、管理咨询、投资银行等多个行业。
          </p>
        </div>
        <div class="team-foot">
          <div
            class="item"
            :class="{ active: item.active }"
            v-for="(item, index) in team"
            :key="`team${index}`"
            @click="item.active = !item.active"
          >
            <img class="avatar" :src="item.avatar" alt="" />
            <div class="user">
              <strong class="name">{{ item.name }}</strong>
              <span class="label">{{ item.label }}</span>
            </div>
            <div class="mask">
              <h1 class="title">
                <strong class="name">{{ item.name }}</strong>
                <span class="label">{{ item.label }}</span>
              </h1>
              <p class="desc">{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-product row-center">
      <div class="section-box product">
        <div class="product-head">
          <div class="icon"></div>
          <h1 class="title">
            <strong>系列产品</strong>
            <span>让中华高级料理走进千家万户</span>
          </h1>
        </div>
        <div class="product-body">
          <div class="item">
            <img src="@/assets/home/product1.jpg" alt="" />
            <div class="mask">
              <p>复合味料</p>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/home/product2.jpg" alt="" />
            <div class="mask">
              <p>卤制系列</p>
            </div>
          </div>
          <div class="item">
            <img src="@/assets/home/product3.jpg" alt="" />
            <div class="mask">
              <p>预制系列</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-partner row-center">
      <div class="section-box partner">
        <div class="partner-head">
          <div class="icon"></div>
          <h1 class="title">
            <strong>合作伙伴</strong>
          </h1>
        </div>
        <div class="partner-body">
          <div class="item">
            <img src="@/assets/home/partner1.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner2.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner3.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner4.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner5.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner6.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner7.jpg" alt="" />
          </div>
          <div class="item">
            <img src="@/assets/home/partner8.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="section-news row-center">
      <div class="section-box news">
        <div class="news-head">
          <div class="icon"></div>
          <h1 class="title">
            <strong>新闻资讯</strong>
            <span @click="$router.push('news')"></span>
          </h1>
        </div>
        <div class="news-body">
          <a :href="item.link" class="item" v-for="(item, index) in news" :key="index">
            <div class="time">
              <strong>{{ item.time | formatMMDD }}</strong>
              <span>{{ item.time | formatYY }}</span>
            </div>
            <div class="article">
              <strong>{{ item.title }}</strong>
              <span>{{ item.summary }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ARTICLE_LIST } from "@/utils/const.js";

export default {
  name: "Home",
  data() {
    return {
      team: [
        {
          active: false,
          avatar: require("@/assets/home/team1.png"),
          name: "陈湘",
          label: "创始人",
          desc:
            "创立培尔留学、图灵未来资本等，yooz电子烟发起人，兼任潮创会主席团常务副主席，潮创会广州分会会长，潮州市厨师协会副会长。美国加州大学伯克利分校环境经济与政策专业; 长江商学院36期EMBA; 清华五道口金融学院15期EMBA学员。"
        },
        {
          active: false,
          avatar: require("@/assets/home/team2.png"),
          name: "陈怀博",
          label: "联合创始人",
          desc:
            "拥有超过5年投资与投后管理经验，深度参与多个项目供应链管理，包括全球购骑士特权、YOOZ 电子烟等早期供应链优化。曾任职于德勤 Deloitte、招商银行私人银行部、图灵未来资本。香港大学工学硕士，工业工程与物流管理专业。"
        },
        {
          active: false,
          avatar: require("@/assets/home/team3.png"),
          name: "佘锡伟",
          label: "资深产品顾问",
          desc:
            "2018年创立骑士特权电商。曾任职于酷狗音乐、网易、腾讯，担任产品研发负责人。曾任租租车CGO ，打造驾照翻译件，实现零成本获客千万。潮创会广州分会副会长，广州市产业高端人才，广东省网商协会副会长。"
        }
      ],
      news: ARTICLE_LIST
    };
  },
  filters: {
    formatMMDD(val) {
      if (!val) return val;
      const [YY, MM, DD] = val.split(".");
      return `${MM}.${DD}`;
    },
    formatYY(val) {
      if (!val) return val;
      const [YY, MM, DD] = val.split(".");
      return YY;
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .home {
    .section-vision {
      background: url("../../assets/home/vision-m.jpg") no-repeat center center;
      background-size: cover;
      width: 100%;
      height: 6rem;
    }
    .section-profile {
      background: #fff;
      padding: 0.6rem 0.6rem 0.26rem 0.6rem;
      box-sizing: border-box;
      .profile {
        display: flex;
        flex-direction: column-reverse;
        &-body {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            font-family: PingFangSC-Medium;
            font-size: 0.36rem;
            line-height: 0.5rem;
            color: #e76d01;
            margin: 0 0 0.12rem 0;
            text-align: left;
          }
          .content {
            font-family: PingFangSC-Light;
            font-size: 0.26rem;
            color: #333333;
            line-height: 0.5rem;
            text-align: left;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .more {
            float: right;
            width: 1.2rem;
            height: 0.4rem;
            border: 0.02rem solid #e76d00;
            border-radius: 0.4rem;
            font-family: PingFangSC-Medium;
            font-size: 0.18rem;
            color: #e76d00;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.22rem;
          }
        }
        &-foot {
          width: 100%;
          height: 4rem;
          border-radius: 0.06rem;
          overflow: hidden;
          margin-bottom: 0.36rem;
          & > img {
            width: 100%;
            height: 100%;
            object-fit: fill;
          }
        }
      }
    }
    .section-investment {
      padding: 0.6rem;
      box-sizing: border-box;
      .investment {
        font-size: 0;
        &-head {
          .title {
            font-family: PingFangSC-Medium;
            font-size: 0.36rem;
            color: #e76d01;
            line-height: 0.5rem;
            text-align: left;
            margin: 0;
          }
        }
        &-body {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 0.12rem;
          .item {
            flex: 0 0 auto;
            width: 2rem;
            height: 0.9rem;
            border-radius: 0.06rem;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
    .section-team {
      background: #fff;
      padding: 0.6rem 0.6rem 0.35rem 0.6rem;
      box-sizing: border-box;
      .team {
        font-size: 0;
        &-head {
          .title {
            font-family: PingFangSC-Medium;
            font-size: 0.36rem;
            color: #e76d01;
            line-height: 0.5rem;
            text-align: left;
            margin: 0;
          }
        }
        &-body {
          margin-top: 0.12rem;
          & > p {
            font-family: PingFangSC-Light;
            font-size: 0.26rem;
            color: #333333;
            text-align: center;
            line-height: 0.44rem;
            margin: 0;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 3;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
        }
        &-foot {
          width: 100%;
          margin-top: 0.36rem;
          display: flex;
          flex-direction: column;
          .item {
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;
            width: 100%;
            height: 2.1rem;
            overflow: hidden;
            border-radius: 0.06rem;
            color: #333333;
            &:not(:last-child) {
              margin-bottom: 0.3rem;
            }
            .avatar {
              width: 2.1rem;
              height: 100%;
              border-radius: 50%;
              overflow: hidden;
              flex: 0 0 auto;
              margin-right: 0.5rem;
            }
            .user {
              display: flex;
              flex-direction: column;
            }
            .name {
              font-family: PingFangSC-Medium;
              font-size: 0.32rem;
              line-height: 0.36rem;
              margin-bottom: 0.04rem;
              text-align: left;
            }
            .label {
              font-family: PingFangSC-Light;
              font-size: 0.26rem;
              line-height: 0.36rem;
              text-align: left;
            }

            .mask {
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 4;
              background: rgba(231, 109, 1, 0.85);
              padding: 0.16rem 0.22rem;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              color: #fff;
              opacity: 0;
              transition: opacity 0.3s ease-in;
              .title {
                margin: 0;
                text-align: left;
                display: flex;
                align-items: baseline;
                .name {
                  font-family: PingFangSC-Medium;
                  font-size: 0.36rem;
                  margin-right: 0.12rem;
                }
                .label {
                  font-family: PingFangSC-Light;
                  font-size: 0.21rem;
                }
              }
              .desc {
                font-family: PingFangSC-Light;
                font-size: 0.2rem;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 0.34rem;
                text-align: justify;
                margin: 0.04rem 0 0;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 4;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }
          }
          .active {
            .mask {
              opacity: 1;
            }
          }
        }
      }
    }
    .section-product {
      padding: 0.6rem;
      box-sizing: border-box;
      .product {
        display: flex;
        flex-direction: column;
        &-head {
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 0.24rem;
              color: #e76d01;
              line-height: 0.4rem;
              font-weight: 400;
            }
          }
        }
        &-body {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 0.3rem;
          .item {
            width: 100%;
            height: 2.5rem;
            border-radius: 0.06rem;
            overflow: hidden;
            position: relative;
            &:not(:last-child) {
              margin-bottom: 0.22rem;
            }
            & > img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
            .mask {
              background-image: linear-gradient(90deg, rgba(231, 109, 1, 0.04) 10%, #e76d01 73%);
              position: absolute;
              right: 0;
              top: 0;
              z-index: 10;
              width: 3.75rem;
              height: 100%;
              padding-left: 1rem;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              overflow: hidden;
              & > p {
                font-family: PingFangSC-Medium;
                font-size: 0.32rem;
                color: #ffffff;
              }
              &::after {
                content: "";
                width: 0.6rem;
                height: 0.02rem;
                background: #fff;
                margin-top: 0.16rem;
              }
            }
          }
        }
      }
    }
    .section-partner {
      padding: 0 0.6rem 0.1rem;
      box-sizing: border-box;
      .partner {
        &-head {
          display: flex;
          flex-direction: column;
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 0.24rem;
              color: #e76d01;
              line-height: 0.4rem;
              font-weight: 400;
            }
          }
        }
        &-body {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 0.32rem;
          .item {
            width: 3rem;
            height: 1.4rem;
            border-radius: 0.06rem;
            overflow: hidden;
            margin-bottom: 0.2rem;
            & > img {
              width: 100%;
              height: 100%;
              object-fit: fill;
            }
          }
        }
      }
    }
    .section-news {
      background: #fff;
      padding: 0.4rem 0.6rem;
      box-sizing: border-box;
      .news {
        &-head {
          .icon {
            display: none;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin: 0;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 0.36rem;
              color: #e76d01;
              line-height: 0.5rem;
            }
            & > span {
              width: 0.7rem;
              height: 0.4rem;
              background: url("../../assets/home/news2.png") no-repeat center;
              background-size: 100%;
            }
          }
        }
        &-body {
          margin-top: 0.3rem;
          .item {
            width: 100%;
            display: flex;
            align-items: center;
            &:not(:last-child) {
              margin-bottom: 0.4rem;
            }
            .time {
              flex: 0 0 auto;
              width: 1.72rem;
              display: flex;
              flex-direction: column;
              & > strong {
                font-size: 0.48rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d01;
                line-height: 0.48rem;
                margin-bottom: 0.1rem;
                text-align: left;
              }
              & > span {
                font-size: 0.24rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 0.24rem;
                text-align: left;
              }
            }
            .article {
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              & > strong {
                font-size: 0.24rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 0.24rem;
                margin-bottom: 0.02rem;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
              }
              & > span {
                font-size: 0.22rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 0.3rem;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 750px) {
  .home {
    .section-vision {
      background: url("../../assets/home/vision.jpg") no-repeat center center;
      background-size: cover;
      min-width: 1190px;
      height: 800px;
    }
    .section-profile {
      background: #fff;
      padding: 150px 0 120px;
      box-sizing: border-box;
      .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-body {
          display: flex;
          flex-direction: column;
          .icon {
            width: 303px;
            height: 25px;
            background: url("../../assets/home/profile0.png") no-repeat;
            background-size: cover;
            margin-bottom: 20px;
          }
          .title {
            font-family: PingFangSC-Medium;
            font-size: 32px;
            color: #e76d01;
            margin: 0 0 26px 0;
            text-align: left;
          }
          .content {
            font-family: PingFangSC-Light;
            font-size: 20px;
            color: #333333;
            line-height: 40px;
            text-align: left;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 4;
            word-break: break-all;
            display: -webkit-box;
            -webkit-box-orient: vertical;
          }
          .more {
            width: 120px;
            height: 40px;
            border: 2px solid #e76d00;
            border-radius: 40px;
            font-family: PingFangSC-Medium;
            font-size: 18px;
            color: #e76d00;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 70px;
          }
        }
        &-foot {
          flex: 0 0 auto;
          width: 580px;
          height: 400px;
          border-radius: 6px;
          overflow: hidden;
          margin-left: 48px;
          & > img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .section-investment {
      padding: 64px 0 60px;
      box-sizing: border-box;
      .investment {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-head {
          background: url("../../assets/home/investment0.png") no-repeat center center;
          background-size: 100%;
          width: 279px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          .title {
            font-family: PingFangSC-Medium;
            font-size: 32px;
            color: #e76d01;
            line-height: 32px;
          }
        }
        &-body {
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-top: 25px;
          .item {
            flex: 0 0 auto;
            width: 380px;
            height: 180px;
            border-radius: 6px;
            overflow: hidden;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .section-team {
      padding: 60px 0 86px;
      box-sizing: border-box;
      .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        &-head {
          background: url("../../assets/home/team0.png") no-repeat center center;
          background-size: 100%;
          width: 383px;
          height: 45px;
          display: flex;
          align-items: center;
          justify-content: center;
          .title {
            font-family: PingFangSC-Medium;
            font-size: 32px;
            color: #e76d01;
            line-height: 32px;
          }
        }
        &-body {
          margin-top: 26px;
          width: 940px;
          & > p {
            font-family: PingFangSC-Light;
            font-size: 20px;
            color: #333333;
            text-align: center;
            line-height: 40px;
            margin: 0;
          }
        }
        &-foot {
          width: 100%;
          margin-top: 104px;
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          .item {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            width: 260px;
            height: 380px;
            overflow: hidden;
            border-radius: 6px;
            color: #333333;
            .avatar {
              width: 100%;
              height: 260px;
              border-radius: 50%;
              overflow: hidden;
            }
            .user {
              display: flex;
              flex-direction: column;
              align-items: center;
            }
            .name {
              font-family: PingFangSC-Medium;
              font-size: 30px;
              line-height: 36px;
              margin-top: 32px;
            }
            .label {
              font-family: PingFangSC-Light;
              font-size: 18px;
              line-height: 36px;
              margin-top: 15px;
            }
            &:hover {
              .mask {
                opacity: 1;
              }
            }
            .mask {
              position: absolute;
              width: 100%;
              height: 100%;
              z-index: 4;
              background: rgba(231, 109, 1, 0.85);
              padding: 52px 30px 0;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              color: #fff;
              opacity: 0;
              transition: opacity 0.3s ease-in;
              .title {
                margin: 0;
                text-align: left;
                .name {
                  font-family: PingFangSC-Medium;
                  font-size: 24px;
                  margin-right: 10px;
                }
                .label {
                  font-family: PingFangSC-Light;
                  font-size: 18px;
                }
              }
              .desc {
                font-family: PingFangSC-Light;
                font-size: 18px;
                color: #ffffff;
                letter-spacing: 0;
                line-height: 24px;
                text-align: justify;
                margin: 10px 0 0;
              }
            }
          }
        }
      }
    }
    .section-product {
      background: #fff;
      padding: 100px 0;
      box-sizing: border-box;
      .product {
        display: flex;
        flex-direction: column;
        &-head {
          .icon {
            width: 277px;
            height: 45px;
            background: url("../../assets/home/product0.png") no-repeat center;
            background-size: 100%;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 60px;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
            & > span {
              font-family: PingFangSC-Light;
              font-size: 20px;
              color: #333333;
              line-height: 20px;
              font-weight: 400;
            }
          }
        }
        &-body {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .item {
            width: 380px;
            height: 498px;
            border-radius: 6px;
            overflow: hidden;
            position: relative;
            & > img {
              width: 100%;
              height: 100%;
            }
            .mask {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 220px;
              background-image: linear-gradient(180deg, rgba(231, 109, 1, 0.07) 0%, #e76d01 100%);
              padding-top: 110px;
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: center;
              & > p {
                font-family: PingFangSC-Medium;
                font-size: 30px;
                color: #ffffff;
                margin: 0 0 16px 0;
              }
              &::after {
                content: "";
                width: 60px;
                height: 2px;
                background: #fff;
              }
            }
          }
        }
      }
    }
    .section-partner {
      padding: 80px 0 60px;
      box-sizing: border-box;
      .partner {
        &-head {
          display: flex;
          flex-direction: column;
          .icon {
            width: 383px;
            height: 45px;
            background: url("../../assets/home/partner0.png") no-repeat center;
            background-size: 100%;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 60px;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
          }
        }
        &-body {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            width: 280px;
            height: 130px;
            border-radius: 6px;
            overflow: hidden;
            margin-bottom: 20px;
            & > img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .section-news {
      background: #fff;
      padding: 110px 0 120px;
      box-sizing: border-box;
      .news {
        &-head {
          .icon {
            width: 401px;
            height: 45px;
            background: url("../../assets/home/news1.png") no-repeat center;
            background-size: 100%;
            margin-bottom: 10px;
          }
          .title {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 0 0 20px;
            & > strong {
              font-family: PingFangSC-Medium;
              font-size: 32px;
              color: #e76d01;
              line-height: 32px;
            }
            & > span {
              width: 59px;
              height: 40px;
              background: url("../../assets/home/news2.png") no-repeat center;
              background-size: 100%;
            }
          }
        }
        &-body {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .item {
            padding: 40px 0;
            box-sizing: border-box;
            display: flex;
            width: 570px;
            display: flex;
            align-items: center;

            .time {
              flex: 0 0 auto;
              width: 152px;
              display: flex;
              flex-direction: column;
              & > strong {
                font-size: 40px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #e76d01;
                line-height: 40px;
                margin-bottom: 20px;
                text-align: left;
              }
              & > span {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                text-align: left;
              }
            }
            .article {
              flex: 1 1 auto;
              display: flex;
              flex-direction: column;
              overflow: hidden;
              & > strong {
                font-size: 24px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #333333;
                line-height: 24px;
                margin-bottom: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-align: left;
              }
              & > span {
                font-size: 20px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                word-break: break-all;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                text-align: left;
              }
            }
            &:nth-child(1),
            &:nth-child(2) {
              border-bottom: 1px solid #d7d7d7;
            }
          }
        }
      }
    }
  }
}
</style>
